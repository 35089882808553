import styled from 'styled-components';
import tw from 'tailwind.macro';

export const List = styled.dl`
  ${tw`text-sm px-4 py-5 sm:px-6`};
`;

export const Title = styled.dt`
  ${tw`py-2 pt-8 text-base font-bold`};
`;

export const TitleDescription = styled.div`
  ${tw`px-6 mt-1 text-base text-gray-900 sm:mt-0`};
`;

export const Description = styled.dd`
  ${tw`pt-2 px-8 mt-1 text-sm text-gray-900 sm:mt-0`};
`;

export const Subtitle = styled.dd`
  ${tw`pt-4 px-4 text-base font-midium`};
`;

export const DescriptionList = styled.div`
  ${tw`py-1 px-10 text-sm`};
`;