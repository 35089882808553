import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/ui/Container';
import * as Styled from './styles';

import { SectionTitle } from 'helpers/definitions';

import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';

interface Privacy extends SectionTitle {
    content: string;
    linkTo: string;
    linkText: string;
}

const Privacy: React.FC = () => {
    const { markdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "privacy section" } }) {
        frontmatter {
          title
          subtitle
          content
          linkTo
          linkText
        }
      }
    }
  `);

    const privacy: Privacy = markdownRemark.frontmatter;

    return (
        <Container section>
            <div class="min-w-full bg-white max-w-2xl shadow overflow-hidden sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        プライバシーポリシー
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Privacy Policy　（個人情報取扱方針）
                    </p>
                </div>
                <div class="border-t border-gray-200">
                    <div class="pt-10 sm:px-6 text-base leading-6 text-gray-900">
                        当社は、当社が取得した個人情報の取扱いに関し、個人情報の保護に関する法律、個人情報保護に関するガイドライン等の指針、その他個人情報保護に関する関係法令を遵守します。
                    </div>
                    <Styled.List>
                        <Styled.Title>1．取得する情報およびその取得方法</Styled.Title>
                        <Styled.TitleDescription>当社が取得するユーザー情報は、取得方法に応じて以下のとおりとなります。</Styled.TitleDescription>
                        
                        <Styled.Subtitle>（1）ユーザーから直接取得する情報と取得方法</Styled.Subtitle>
                        <Styled.Description>
                            当社は、当社が提供するインターネットサイト（以下「本サイト」）の運営に必要な範囲で、本サイトの利用者（以下「ユーザー」）から、個人情報を取得することがあります。
                        </Styled.Description>

                        <Styled.Subtitle>（2）情報の利用目的</Styled.Subtitle>
                        <Styled.Description>当社は、取得した個人情報を以下に定める目的のために使用します。</Styled.Description>
                        <Styled.DescriptionList>(ⅰ)コンサート情報の配信</Styled.DescriptionList>

                        <Styled.Title>2．個人情報の管理</Styled.Title>
                        <Styled.TitleDescription>当社は、ユーザーから取得した個人情報の管理について、以下を徹底します。</Styled.TitleDescription>                                                
                            <Styled.Subtitle>（1）情報の正確性の確保</Styled.Subtitle>
                            <Styled.Description>取得した個人情報については、常に正確かつ最新の情報となるよう努めます。
                        </Styled.Description>
                        
                            <Styled.Subtitle>（2）安全管理措置</Styled.Subtitle>
                            <Styled.Description>当社は、組織的な個人情報の管理については、社内規定による厳重な取扱方法を規定し、規定に基づいた取扱いと厳格な運用を徹底しています。
                        </Styled.Description>
                            <Styled.Subtitle>（3）個人情報管理の委託先の監督</Styled.Subtitle>
                            <Styled.Description>個人情報の管理を外部委託する場合には、当社の規程に基づく委託先にのみ委託し適切に管理します。
                        </Styled.Description>
                        
                            <Styled.Subtitle>（4）個人情報の保存期間と廃棄</Styled.Subtitle>
                            <Styled.Description>取得した個人情報は、保存期間を設定し、保存期間終了後は廃棄します。
                        </Styled.Description>
                        <Styled.Title>3．個人情報の第三者への提供</Styled.Title>
                        <Styled.TitleDescription>
                            当社は、取得した個人情報を、第三者に提供することはありません。また、今後、第三者提供を行う場合は、提供する情報と目的を提示し、ユーザーの同意を得た場合のみ行います。
                        </Styled.TitleDescription>
                        <Styled.Title>4．個人情報の開示・訂正・利用停止</Styled.Title>
                        <Styled.TitleDescription>
                            個人情報について、開示、訂正、利用停止等のお申し出があった場合には、本人の申し出であることを確認の上、当社所定の方法に基づき対応致します。具体的な方法は、個別にご案内しますので、<a href="/contact">弊社問い合わせ窓口</a>までお問い合わせください。
                        </Styled.TitleDescription>
                        <Styled.Title>5．お問い合わせ先</Styled.Title>
                        <Styled.TitleDescription>
                            弊社サービス、個人情報の取扱いについては、<a href="/contact">弊社問い合わせ窓口</a>にご連絡ください。
                        </Styled.TitleDescription>
                        <Styled.Title>6．セキュリティ</Styled.Title>
                        <Styled.TitleDescription>
                            当社がウェブサイト上で取り扱う個人情報は、全ての通信経路においてSSLによって暗号化されており、第三者によって閲覧されることはありません。
                        </Styled.TitleDescription>
                        <Styled.Title>7．制定日</Styled.Title>
                        <Styled.TitleDescription>
                            2021年4月1日
                        </Styled.TitleDescription>
                    </Styled.List>
                </div>
            </div>
        </Container>
    );
};

export default Privacy;
