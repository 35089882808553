import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Privacy from 'components/Privacy';

const PrivacyPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="プライバシーポリシー" />
      <Privacy />
    </Layout>
  );
};

export default PrivacyPage;
